import cx from 'clsx';
import styles from './index.module.scss';

interface Props {
  label: string;
  onClick: any;
  disabled?: boolean;
}

function AngleButton({ label, onClick, disabled }: Props) {
  return (
    <button
      className={cx(styles.root, disabled && styles.disabled)}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <span />
      <span>{label}</span>
    </button>
  );
}

export default AngleButton;
