import { useEffect } from 'react';

export function useInitPosition() {
  let lastScrollTop1 = 0;
  let lastScrollTop2 = 0;

  function positionTheDot() {
    const container: HTMLElement | null =
      document.querySelector('.container-fourth');

    if (!container) {
      return;
    }

    const svg1: SVGPathElement | null = document.querySelector('#road1');
    const svg2: SVGPathElement | null = document.querySelector('#road2');

    function firstPosition(imageClass: string) {
      if (container && svg1) {
        const scrollElement =
          container?.getBoundingClientRect().height +
          container?.getBoundingClientRect().top;
        const pathLen = svg1.getTotalLength();
        const scrollPercentage =
          1 - scrollElement / container.getBoundingClientRect().height;
        const pt = svg1.getPointAtLength(
          scrollPercentage *
            (pathLen + container.getBoundingClientRect().height)
        );

        const st = window.pageYOffset || document.documentElement.scrollTop;

        let deg = 0;

        if (st > lastScrollTop1) {
          if (pt.y > 252) {
            deg = -45;
          }
          if (pt.y > 268) {
            deg = -90;
          }
          if (pt.y > 277) {
            deg = -45;
          }
          if (pt.y > 297) {
            deg = 0;
          }
        } else {
          deg = -180;

          if (pt.y < 285) {
            deg = -225;
          }
          if (pt.y < 269) {
            deg = -270;
          }
          if (pt.y < 262) {
            deg = 135;
          }
          if (pt.y < 246) {
            deg = 180;
          }
        }

        lastScrollTop1 = st <= 0 ? 0 : st;

        const dot = document.getElementById(imageClass);

        if (dot) {
          dot.setAttribute(
            'transform',
            `translate(${pt.x},${pt.y}) rotate(${deg})`
          );

          if (scrollPercentage > 0.387) {
            dot.style.display = 'none';
          } else {
            dot.style.display = 'block';
          }
        }
      }
    }

    function secondPosition(imageClass: string) {
      if (container && svg2) {
        const scrollElement =
          container.getBoundingClientRect().height -
          500 +
          container.getBoundingClientRect().top;
        const pathLen = svg2.getTotalLength();
        const scrollPercentage =
          1 - scrollElement / container.getBoundingClientRect().height;
        const pt = svg2.getPointAtLength(
          (pathLen + 2850) * (1 - scrollPercentage)
        );

        const st = window.pageYOffset || document.documentElement.scrollTop;

        let deg = 0;

        if (st > lastScrollTop2) {
          if (pt.y > 215) {
            deg = 45;
          }
          if (pt.y > 237) {
            deg = 90;
          }
          if (pt.y > 245) {
            deg = 45;
          }
          if (pt.y > 275) {
            deg = 0;
          }
        } else {
          deg = -180;

          if (pt.y < 255) {
            deg = -135;
          }
          if (pt.y < 240) {
            deg = -90;
          }
          if (pt.y < 235) {
            deg = -135;
          }
          if (pt.y < 210) {
            deg = -180;
          }
        }
        lastScrollTop2 = st <= 0 ? 0 : st;

        const dot = document.getElementById(imageClass);

        if (dot) {
          dot.setAttribute(
            'transform',
            `translate(${pt.x},${pt.y}) rotate(${deg})`
          );

          if (scrollPercentage > 0.79) {
            dot.style.display = 'block';
          } else {
            dot.style.display = 'none';
          }
        }
      }
    }

    if (svg1) {
      firstPosition('imagedot1');
    }

    if (svg2) {
      secondPosition('imagedot2');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', positionTheDot);
    positionTheDot();
  });
}
