import { useTranslation } from 'react-i18next';
import icons from 'utils/images/jobDetails';

import styles from './index.module.scss';

type IBenefitItemProps = {
  icon: JSX.Element;
  label: string;
};

const BenefitItem = ({ icon, label }: IBenefitItemProps) => {
  return (
    <div className={styles.benefitItem}>
      <div className="icon">
        <span className={styles.benefitItemHighlightContainer}>
          <span className="highlight">{icon}</span>
        </span>
      </div>
      <div className="label">{label}</div>
    </div>
  );
};

export default function Benefits() {
  const [t] = useTranslation('common');
  return (
    <div className={styles.benefitsSectionContent}>
      <h3 className={styles.benefitsTitle}>{t('benefits.haulBenefits')}</h3>
      <div className={styles.benefitsItemsContainer}>
        <BenefitItem icon={icons.medical} label={t('benefits.medical')} />
        <BenefitItem icon={icons.dental} label={t('benefits.dental')} />
        <BenefitItem
          icon={icons.lifeInsurance}
          label={t('benefits.lifeInsurance')}
        />
        <BenefitItem icon={icons.instantPay} label={t('benefits.instantPay')} />
        <BenefitItem icon={icons.dispatch} label={t('benefits.dispatch')} />
        <BenefitItem icon={icons.vision} label={t('benefits.vision')} />
        <BenefitItem icon={icons.bonus} label={t('benefits.bonus')} />
        <BenefitItem icon={icons.training} label={t('benefits.training')} />
        <BenefitItem
          icon={icons.payForHolidays}
          label={t('benefits.holidays')}
        />
        <BenefitItem
          icon={icons.credential}
          label={t('benefits.credentials')}
        />
      </div>
    </div>
  );
}
